<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
  >
    <el-form ref="form" :model="entity" label-width="110px" :rules="rules">
      <el-form-item label="所属公司" prop="SupplieId">
        <CompanySearchSelect v-model="entity.SupplieId" />
      </el-form-item>
      <el-form-item label="用户名" prop="UserName">
        <el-input v-model="entity.UserName" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="RealName">
        <el-input v-model="entity.RealName" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="UserPhone">
        <el-input v-model="entity.UserPhone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label="密码" :prop="title == '编辑用户' ? '' : 'newPwd'">
        <el-input
          v-model="entity.newPwd"
          type="password"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="Sex">
        <el-radio v-model="entity.Sex" :label="0">女</el-radio>
        <el-radio v-model="entity.Sex" :label="1">男</el-radio>
      </el-form-item>
      <el-form-item label="出生日期" prop="Birthday">
        <el-date-picker
          style="width: 100%"
          v-model="entity.Birthday"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上传头像" prop="UserAvatarURL">
        <UploaderAvatar v-model="entity.UserAvatarURL" />
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import UploaderAvatar from '@/components/UploaderAvatar.vue'
import CompanySearchSelect from '@/components/CompanySearchSelect.vue'
const regExp = new RegExp('^1[3456789]\\d{9}$') // 手机号正则
export default {
  props: {
    parentObj: { type: Object },
  },
  components: {
    UploaderAvatar,
    CompanySearchSelect,
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      entity: {},
      rules: {
        SupplieId: [
          { required: true, message: '请选择公司', trigger: 'change' },
        ],
        UserName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        RealName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        UserPhone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        Sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
      },
      btnLoading: false,
      title: '',
      loading: false,
      timeout: null,
    }
  },
  methods: {
    init() {
      this.entity = {}
      this.drawer = true
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate()
      }
    },
    openForm(Id, CompanyId) {
      this.init()
      this.title = '新增用户'
      if (CompanyId) {
        this.entity.SupplieId = CompanyId
      }
      this.entity.IsSubAccount = true
      if (Id) {
        this.title = '编辑用户'
        this.getTheData(Id)
      }
    },
    handleClose(done) {
      done()
    },
    handleSelect(item) {
      console.log(item)
    },
    getTheData(id) {
      this.$http
        .post('/Base_Manage/Base_User/GetTheData', { id })
        .then((res) => {
          if (res.Success) {
            this.entity = res.Data
          } else {
            this.$message.error(res.Msg)
          }
        })
    },
    handleSubmit() {
      console.log(this.entity)
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        }
        this.btnLoading = true
        this.$http
          .post('/Base_Manage/Base_User/SaveData', this.entity)
          .then((res) => {
            this.btnLoading = false
            if (res.Success) {
              this.$message.success('操作成功')
              this.parentObj.getDataList()
              this.drawer = false
            } else {
              this.$message.error(res.Msg)
            }
          })
      })
    },
  },
}
</script>

<style>
.drawerClass {
  padding: 10px;
}
</style>
