<template>
  <el-upload
    class="avatar-uploader"
    :action="$baseUrl + '/Base_Manage/Upload/Filleimag'"
    :headers="{
      Authorization: token,
    }"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
    <el-image class="avatar" :src="imageUrl" :fit="'cover'" v-if="imageUrl">
      <div slot="error" class="image-slot">
        <el-image
          class="avatar"
          :src="require('@/assets/logo.png')"
          :fit="'cover'"
        >
        </el-image>
      </div>
    </el-image>
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
export default {
  props: {
    value: { default: '', require: false, type: String },
  },
  watch: {
    value(o) {
      this.imageUrl = o
    },
  },
  data() {
    return {
      imageUrl: '',
      token: null,
    }
  },
  mounted() {
    let token = sessionStorage.getItem('token')
    this.token = 'Bearer ' + token
  },
  methods: {
    handleAvatarSuccess(res, file) {
      if (res.Success) {
        this.imageUrl = res.Data
        this.$emit('input', this.imageUrl)
      } else {
        this.$message.error('头像上传失败,请重试')
      }
      console.log(res, file)
    },
    beforeAvatarUpload(file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!')
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isJPG
    },
  },
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
